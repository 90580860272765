<template>
  <div
    class="page about"
    :loaded="Boolean(page)"
  >
    <section class="top-section" v-if="page">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${page.image})` }">
          <div class="content">
            <h1 v-html="page.title.replace(/\n/g, '<br>')"></h1>
            <h3 v-html="page.tagline.replace(/\n/g, '<br>')"></h3>
            <router-link class="btn white" to="/contact">Contact us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section text-section" v-if="page">
      <div class="wrapper">
        <div class="text-container">
          <div class="text-aside" v-html="page.intro.replace(/\n/g, '<br>')"></div>
          <div class="text-paragraph">
            <p v-html="page.description.replace(/\n/g, '<br>')"></p>
            <router-link class="btn medium" to="/contact">Contact us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section image-text" v-if="page && page.sections[0]">
      <div class="wrapper">
        <div class="container">
          <img :src="page.sections[0].image" alt="" class="image">
          <div class="content">
            <h3 v-html="page.sections[0].name.replace(/\n/g, '<br>')"></h3>
            <h5 v-html="page.sections[0].tagline.replace(/\n/g, '<br>')"></h5>
            <div class="text">
              <p v-html="page.sections[0].description.replace(/\n/g, '<br>')"></p>
              <p><b>{{ page.sections[0].cta_text }}</b></p>
            </div>
            <router-link class="btn medium" to="/contact">Get in touch</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="wrapper services">
      <div class="section-title centered">
        <h3>Why Choose WestBIC<br> for Your Start-up & Scaling Needs?</h3>
        <h5>Your All-in-One Growth Boost</h5>
      </div>
      <div class="container">
        <div class="service">
          <img src="@/assets/images/wb_ic_money.svg" alt="" class="service-icon">
          <div class="service-title">Funding<br> Advisory</div>
          <div class="service-description">Navigate national funding options like Enterprise Ireland’s Pre-Seed Start Fund (PSSF) and Innovative High Potential Start-Ups (iHPSU).</div>
        </div>
        <div class="service">
          <img src="@/assets/images/wb_ic_speech.svg" alt="" class="service-icon">
          <div class="service-title">Investor<br> Readiness</div>
          <div class="service-description">Prepare a compelling pitch and business plan to attract investors.</div>
        </div>
        <div class="service">
          <img src="@/assets/images/wb_ic_leaf.svg" alt="" class="service-icon">
          <div class="service-title">Business Planning<br> & Strategy</div>
          <div class="service-description">Develop a scalable business model with expert guidance.</div>
        </div>
        <div class="service">
          <img src="@/assets/images/wb_ic_group.svg" alt="" class="service-icon">
          <div class="service-title">Networking<br> & Mentorship</div>
          <div class="service-description">Gain the tools needed to scale your startup globally.</div>
        </div>
        <div class="service">
          <img src="@/assets/images/wb_ic_rocket.svg" alt="" class="service-icon">
          <div class="service-title">Commercialisation<br> & Growth</div>
          <div class="service-description">Connect with industry experts, investors, and other startups.</div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="wrapper">
        <div class="section-footer">
          <p><b>Become Investor Ready Today</b></p>
          <router-link class="btn medium" to="/contact">Get in touch</router-link>
        </div>
      </div>
    </section>

    <section class="section offices">
      <div class="wrapper">
        <div class="section-title centered">
          <h3>Offices</h3>
          <h5>With offices located in Galway, Mayo, Roscommon and Donegal...</h5>
        </div>
        <div class="container">
          <div class="office">
            <img src="@/assets/images/wb_o_office-galway.svg" alt="Galway">
          </div>
          <div class="office">
            <img src="@/assets/images/wb_o_office-mayo.svg" alt="Mayo">
          </div>
          <div class="office">
            <img src="@/assets/images/wb_o_office-roscommon.svg" alt="Roscommon">
          </div>
          <div class="office">
            <img src="@/assets/images/wb_o_office-donegal.svg" alt="Donegal">
          </div>
        </div>
        <div class="section-footer">
          <p>We work with innovative and ambitious entrepreneurs in Galway, Mayo, Roscommon, Sligo, Leitrim, Donegal, Westmeath, Longford.</p>
          <router-link class="btn medium" to="/contact">Get in touch!</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import { mapGetters } from "vuex";

export default {
  name: "About",
  components: {
    Icon,
  },
  data() {
    return {
      page: null,
      loaded: false,
      backgroundImage: null,
      selectedSection: 0,
      scrolled: false,
    };
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
      isMobile: "getMobile",
      testMode: "getTest",
    }),
    db() {
      return this.$store.state.firebase.firestore;
    },
  },
  methods: {
    getPage() {
      if (this.pageRef) this.pageRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("whatwedo")
        .onSnapshot((wwd) => {
          this.page = {
            title: "",
            description: "",
            image: "",
            ...wwd.data(),
          };
        });
    },
  },
  watch: {
    testMode() {
      this.getPage();
    },
  },
  mounted: function() {
    this.getPage();
  },
  beforeDestroy: function() {
    if (this.pageRef) this.pageRef();
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 4rem;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  overflow: hidden;
  padding-top: 53.4%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 64px 96px;
  background: rgb(10,126,140);
  background: linear-gradient(36deg, rgba(10, 126, 140, 0.80) 13.82%, rgba(10, 126, 140, 0.50) 32.99%, rgba(10, 126, 140, 0.00) 64.59%);
}
.top-section .container .content .logo {
  display: block;
  width: 414px;
  max-width: 100%;
}
.top-section .container .content h1 {
  font-size: 8rem;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 4rem;
  margin-bottom: 32px;
  line-height: 1.1625;
  max-width: 980px;
}
.top-section .container .content p {
  font-size: 1.125rem;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 40%;
  padding: 0 48px;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 60%;
  padding: 0 48px;
  font-size: 1.5rem;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.image-text .container {
  display: flex;
}
.image-text .image {
  width: 580px;
  object-fit: cover;
  border-radius: 12px;
}
.image-text .content {
  flex-grow: 1;
  padding: 0 96px 16px;
}
.image-text h3 {
  font-size: 4rem;
  line-height: 1.166666;
  margin-bottom: 24px;
  color: var(--Metallic-Seaweed);
}
.image-text h5 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.166666;
  color: var(--Dark-Blue);
  margin-bottom: 32px;
}
.image-text p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 40px;
}
.image-text .text::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px 0;
}

.services {
  background: url(../assets/images/wb_bg_services.png) no-repeat center/cover;
  border-radius: 12px;
  padding: 80px 24px 112px;
  margin-bottom: 40px;
}
.services .container {
  display: flex;
  justify-content: center;
  gap: 80px 32px;
  flex-wrap: wrap;
  margin-top: 72px;
}
.services .service {
  width: calc(33.333333% - 22px);
  max-width: 538px;
  padding: 0 80px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}
.services .service .service-icon {
  display: block;
  width: 64px;
  margin: 0 auto 8px;
}
.services .service .service-title {
  font-size: 2.25rem;
  font-weight: 600;
  color: var(--Dark-Blue);
}
.services .service .service-title::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 16px auto;
}

@keyframes randomMove {
  0%, to {
    -webkit-transform: none;
    transform: none
  }

  40% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}

.offices .container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.offices .container .office {
  position: relative;
  width: calc(25% - 24px);
}
.offices .container .office::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30px;
  border-radius: 4px;
  margin: auto;
  background: linear-gradient(90deg, #0A7E8C 0%, #0E3A5A 100%);
  animation: randomMove 5s cubic-bezier(.36,0,.64,1) infinite forwards;
}
.offices .container .office:nth-child(2):before {
  animation-duration: 5.5s;
}
.offices .container .office:nth-child(3):before {
  animation-duration: 4.7s;
}
.offices .container .office:nth-child(4):before {
  animation-duration: 6s;
}
.offices .container .office img {
  position: relative;
  display: block;
  width: 100%;
  height: 360px;
  object-fit: contain;
  animation: randomMove 3s cubic-bezier(.36,0,.64,1) infinite reverse;
  animation-delay: 0.3s;
}
.offices .container .office:nth-child(2) img {
  animation-duration: 5.5s;
}
.offices .container .office:nth-child(3) img {
  animation-duration: 4.7s;
}
.offices .container .office:nth-child(4) img {
  animation-duration: 6s;
}

@media screen and (max-width: 1280px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 3rem;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 300px;
  }
  .top-section .container .content h1 {
    font-size: 6rem;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 1.75rem;
  }
  .text-section .text-container .text-paragraph {
    font-size: 1.3125rem;
  }
  .image-text h3 {
    font-size: 3rem;
  }
  .image-text .image {
    width: 50%;
  }
  .image-text .content {
    padding: 64px;
  }
  .image-text h5 {
    font-size: 1.75rem;
  }
  .image-text h5 >>> br {
    display: none;
  }
  .services .service {
    padding: 0 40px;
  }
}

@media screen and (max-width: 1024px) {
  .services .container {
    gap: 48px;
    padding: 0;
  }
  .services .service {
    width: calc(50% - 24px);
    font-size: 1.25rem;
  }
  .services .service .service-title {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 2.5rem;
  }
  .section-title h5 {
    font-size: 1.5rem;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container {
    min-height: 400px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 220px;
  }
  .top-section .container .content h1 {
    font-size: 4.5rem;
  }
  .top-section .container .content h3 {
    font-size: 3rem;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
  .image-text .container {
    display: block;
  }
  .image-text .image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  .image-text .content {
    padding: 24px 48px 0;
  }
  .services .container {
    padding: 0;
  }
  .services .service {
    padding: 0;
  }
  .offices .container .office {
    width: calc(50% - 16px);
  }
  .offices .container .office img {
    height: auto;
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .section-footer {
    font-size: 1.3125rem;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content .logo {
    width: 200px;
  }
  .top-section .container .content h1 {
    font-size: 3rem;
  }
  .top-section .container .content h3 {
    font-size: 2rem;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 1.3125rem;
  }
  .text-section .text-container .text-paragraph {
    font-size: 1.125rem;
  }
  .image-text h3 {
    font-size: 2rem;
  }
  .image-text h3 >>> br {
    display: none;
  }
  .image-text h5 {
    font-size: 1.5rem;
  }
  .image-text p {
    font-size: 1.3125rem;
  }
  .image-text .content {
    padding: 24px 0 0;
  }
  .services .service {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
}
</style>
